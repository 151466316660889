.resend-default-btn {
  padding: 0px 5px 0px 5px;
  box-shadow: unset;
  background: #08a3d3;
  border: 1px solid #08a3d3;
  color: #fff;
  border-radius: 0px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: 12px;
  &:disabled {
    background: #ecf1f2;
    color: #b5b8bb;
    border: 1px solid #ecf1f2;
    cursor: not-allowed;
  }
}
@media (max-width: 767px) {
  .HeaderAlertWrapper{
    .message{
      font-size: .6rem;
    }
    .resend-default-btn{
      min-width: 121px;
      font-size: 10px;
    }
    .MuiAlert-root{
      padding: 6px 8px !important;
    }
    .MuiAlert-message{
      min-width: 0px !important;
    }
    .MuiAlert-icon{
      font-size: 17px !important;
    }
  }
}

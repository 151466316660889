h1, h2,h3, h4{
  font-weight: 700;
  color: #393E43;
  font-family: 'Roboto Condensed', sans-serif;
}
a{
  color: #08A3D3;
}
.container-full{
  width: 84%;
  margin: 0 auto;
}
.head-tile{
  font-size: 24px;
  line-height: 87%;
}
.HeaderContent {
  // background: #007cb1;
  background: #f9fbfd;
  /*   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); */
  height: 173px;

  .margin-0 {
    margin: 0 auto;
  }
  .row {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    // justify-content: space-around;
    align-items: center;
    margin-top: 3%;
  }
}
.loginTitle {
  color: #525ca3;
  font-weight: bold !important;
}

.textDecorationUnderlineDefault {
  text-decoration: underline;
  cursor: pointer;
}

.logo {
  padding: 22px 0px;
  color: #fff;
  font-size: small;
}
.LogoImage {
  max-width: 148px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}

 /* ---Start Header css--- */
    
 .header-wrap{
  background: url(/img/header-bg.svg) no-repeat top right;
  padding: 38px 0px;
  @media (min-width:992px) {
      height: 175px;
      margin-bottom: 16px;
      
  }

  .logo-wrap{
      margin-top: 25px;
  }
  .profile-wrap{
      text-align: right;
      display: flex;
      > div{
        margin-left: auto;
      }
      > .hideOnMobile{
        ul{
          max-width: 229px;
          width: 100%;
          top: 10% !important;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          font-size: 16px;
          li{
           margin-bottom: 6px;
           a{
            padding: 12px 21px;
            color: #636A72;

           }
          }

          li:hover{
            a{
              color:#08A3D3;
              background: #F9FBFD;
            }
          }
        }
          
          .MuiMenuItem-root{
              font-weight: 300;
              font-size: 18px;
              line-height: 28px;
              color: #636A72;
          }
      }
      button{
          min-width: 229px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 15px 45px 15px 20px;
          margin: 0px;
          background:#FFFFFF url(/img/select-drop-arrow.svg) no-repeat center right 20px !important;
          &:focus{
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
          }
          &:active{
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
            &:hover{
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
            }
          }
          span{
              justify-content: left;
              font-size: 20px;
              line-height: 87%;
              color: #393E43;
          }
          &:after{
            border:0px;
          }
      }
  }
}





@media (max-width: 1100px) {
  .logo {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .loginWrapper {
    > div {
      margin-top: 10%;
    }
    .loginTitle {
      font-size: 40px;
      line-height: 42px;
    }
  }

  .logo {
    padding: 13px 0px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    width: 100%;
    margin-left: 6%;
  }
}

.hideOnMobile {
  display: block;
}

@media (max-width: 767px) {
  .hideOnMobile {
    display: none;
  }
  .row {
    justify-content: space-around;
    margin-top: 0%;
  }
}

/*Theme Dark theme Styles*/
$primary-amerikooler-theme: #525ca3 !default;
$primary-amerikooler-header-color: #f9fbfd !default;
$secondary-amerikooler-theme: lighten($primary-amerikooler-theme, 15%);
$amerikooler-theme-text: #ffffff !default;

$sidebar-amerikooler-theme: #f4f7fc !default;
$sidebar-sanofi-color: #2b2b38 !default;
$sidebar-text-amerikooler-theme: $amerikooler-theme-text !default;
$sidebar-darken-amerikooler-theme: darken($primary-amerikooler-theme, 2%);

// Links
$link-amerikooler-theme: #3f51b5;
$link-hover-amerikooler-theme: #32408f;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
//Default Body Style
body.amerikooler-theme {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #393e43;
  font-weight: 400;
  background-color: #f9fbfd;
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    color: #393e43;
    font-family: 'Roboto Condensed', sans-serif;
  }
  a {
    color: #08a3d3;
  }
  .container-full {
    width: 84%;
    margin: 0 auto;
  }
  .loaderForConfigrationPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .head-tile {
    font-size: 24px;
    line-height: 87%;
  }
  .sub-title {
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 16px;
  }
  .default-btn {
    padding: 12px 20px 9px 20px;
    box-shadow: unset;
    background: #08a3d3;
    border: 1px solid #08a3d3;
    color: #fff;
    border-radius: 0px;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    // &:hover{
    //     background: #fff;
    //     color:  #08A3D3;
    //     border:1px solid #08A3D3
    // }
    &:disabled {
      background: #ecf1f2;
      color: #b5b8bb;
      border: 1px solid #ecf1f2;
      cursor: not-allowed;
    }
  }
  .MuiFormLabel-root {
    font-size: 14px;
    border-radius: 0;
  }
  .MuiOutlinedInput-root {
    border-radius: 0;
    border-color: #d9d9d9;
  }
  .MuiOutlinedInput-input {
    padding: 13px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(13px, 13px) scale(1);
  }
  .MuiInputLabel-shrink {
    transform: translate(15px, -6px) scale(0.75);
  }
  .default-btn {
    padding: 12px 20px 9px 20px;
    box-shadow: unset;
    background: #08a3d3;
    color: #fff;
    border-radius: 0px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}
.amerikooler-theme {
  .login-poster-image {
    height: 100vh;
    object-fit: cover;
    object-position: top right;
    width: 100%;
  }
  .des-hide {
    display: none;
  }
  .login-page {
    .login-box-wrap {
      display: flex;
      flex-direction: column;
      // height: 100%;
      justify-content: center;
      max-width: 350px;
      margin: 0 auto;
      margin-top: 25%;
      background: #ffffff;

      .logo-wrap {
        text-align: center;
        margin-bottom: 63px;
      }
      h2 {
        color: #393e43;
        font-weight: 600;
        margin-bottom: 13px;
        font-size: 24px;
      }
      h3 {
        line-height: 28px;
        margin-bottom: 23px;
        font-size: 18px;
        font-weight: normal;
        font-family: 'franklin-gothic-urw-cond', Arial, Helvetica, sans-serif;
      }
      .MuiFormControl-marginNormal {
        margin: 0px 0px 28px 0px;
      }
      .forget-link {
        margin-top: 28px;
        display: inline-block;
      }
      .signup-link {
        margin-top: 50px;
        text-align: center;
      }

      .default-error {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e05757;
        border-radius: 5px;
        display: flex;
        padding: 18px 13px;
        margin: 20px 12px;
        align-items: center;
        color: #393e43;
        img {
          margin-right: 7px;
        }
      }
    }
    .back-button {
      margin-top: 25px;
      margin-left: 15px;
      .back-home-link {
        font-size: 14px;
        color: #08a3d3;
        display: flex;
        align-content: center;
        align-items: center;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        img {
          margin-right: 3px;
          color: #393e43;
        }
      }
    }
    .bk-btn-des {
      margin-top: 25px !important;
      position: absolute;
    }
  }

  .login-heading-wrap {
    background: url(/img/new-login-poster-image.png) no-repeat top right;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-left: 79px;
    padding-bottom: 10%;
    background-size: cover;
    h1 {
      order: 1;
      font-size: 64px;
      line-height: 95%;
      color: #ffd300;
      margin-bottom: 22px;
      text-transform: uppercase;
    }
    p {
      color: #ffffff;
      max-width: 664px;
    }
  }
  .forget-password {
    .default-btn {
      margin-top: 20px;
    }
    .back-link {
      margin-top: 40px;
      font-size: 16px;
      line-height: 28px;
      color: #393e43;
      margin-bottom: 34px;
      img {
        margin-right: 15px;
      }
    }
  }

  .forget-password-page {
    .login-heading-wrap {
      padding-bottom: 13%;
    }
  }
  .full-height {
    height: 100%;
    background: #ffffff;
  }
  .login-page {
    height: 100%;
  }
  .phone-mask {
    .MuiInputBase-root {
      border: 1px solid rgba(0, 0, 0, 0.23);
      input {
        padding: 13px 14px;
        &:focus {
          border: 2px solid #3f51b5;
        }
        &:focus-visible {
          border: none;
        }
      }
      &:before {
        border: none;
      }
      &:focus {
        border: 1px solid #3f51b5;
      }
      &:before {
        border: none;
      }
      .MuiInput-underline {
        &:hover {
          border: none;
        }
      }
    }
    .MuiInput-underline:after {
      border: none;
      &:hover {
        border: none;
      }
      &:focus {
        border: none;
      }
    }
    .MuiInput-underline {
      &:hover {
        border-bottom: none;
      }
    }
    .Mui-error {
      border: 1px solid #e05757;
    }
  }
  .ak-error-icon {
    font-size: 14px;
    margin-top: -4px;
    margin-right: 10px;
  }
  .ak-error-text {
    color: #e05757;
    font-size: 14px;
    margin-top: -10px;
  }
  .stateProfileError {
    .MuiNativeSelect-root {
      border: 1px solid #e05757;
    }
  }
}
//Default card style
.amerikooler-theme {
  .HeaderContent,
  /* header {
    background: $primary-amerikooler-header-color !important;
  } */
  .loader-view {
    color: $primary-amerikooler-theme !important;
    background-color: transparent !important;
  }
}

//_sidebar.scss
.amerikooler-theme .side-nav {
  background-color: $sidebar-amerikooler-theme !important;
  color: $sidebar-sanofi-color !important;
  box-shadow: 0px 4px 20px #00000014;

  & .user-profile {
    background-color: #525ca3 !important;
    padding-top: 24px;
    padding-bottom: 23px;
    @include box-shadow(none);
    position: relative;
    z-index: 2;
    color: $amerikooler-theme-text !important;
  }
  & .user-avatar {
    margin: 0 7px 0 0 !important;
    height: 31px !important;
    width: 31px !important;
    line-height: 44px;
    border: 2px solid #6b7a93;
    background-color: transparent !important;
  }
  & .user-detail h4 {
    color: $amerikooler-theme-text !important;
    font-size: 14px;
    font-weight: normal;
  }

  & .customizer {
    border-bottom: 0 none;
  }
  & .dekstop-logo {
    background-color: #525ca3 !important;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px;
    padding-right: 20px;
  }

  & .user-detail {
    & .user-name {
      color: #fff;
    }
  }
}
//_sidebar.scss for dekstop
.amerikooler-theme .customized-header {
  color: $sidebar-sanofi-color !important;
  box-shadow: 0px 4px 20px #00000014;

  & .user-profile {
    background-color: #525ca3 !important;
    padding-top: 1px;
    padding-bottom: 5px;
    @include box-shadow(none);
    position: relative;
    z-index: 2;
    color: $amerikooler-theme-text !important;
  }

  & .user-avatar {
    margin: 0 7px 0 0 !important;
    height: 31px !important;
    width: 31px !important;
    line-height: 44px;
    border: 2px solid #6b7a93;
    background-color: transparent !important;
  }
  & .user-detail h4 {
    color: $amerikooler-theme-text !important;
    font-size: 14px;
    font-weight: normal;
  }

  & .customizer {
    border-bottom: 0 none;
  }

  & .user-detail {
    & .user-name {
      color: #fff;
    }
  }
}

/* ---Start Signup css-- */
.signup-page {
  overflow-x: auto;
  background: url(/img/signup-header-bg.svg) no-repeat top right;
  position: relative;
  margin: 0px !important;
  &:after {
    content: '';
    width: 218px;
    height: 153px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: url(/img/signup-footer-bg.svg) no-repeat top right;
  }
  h2 {
    text-transform: uppercase;
  }
  h3 {
    font-weight: 400 !important;
  }
  .logoMain {
    text-align: center;
  }
  .registration-box {
    padding-top: 43px;
    width: 75%;
    .MuiFormControl-marginNormal {
      margin-top: 0px;
    }
  }
  .signup-link {
    margin-top: 40px;
    text-align: center;
  }
}
/* ---End Signup css--- */

/* ---Start FooterForCertifiedInstaller css--- */
.amerikooler-theme .FooterForCertifiedInstaller-wrapper {
  .hr-footer-top {
    border: 5px solid rgba(255, 211, 0, 1);
    background-color: rgba(255, 211, 0, 1);
    margin-bottom: 3rem;
  }
  .containerXL {
    position: relative;
    color: #3a3e43;
    font-size: 18px;
    line-height: 1.45;
    font-weight: 400;
    font-family: 'franklin-gothic-urw-cond', Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
    width: 1170px;
    .footer-nav {
      padding: 0;
      list-style: none;
      line-height: 1.8;
      margin-bottom: 0;
      a {
        text-decoration: none;
        text-transform: lowercase;
        color: #393e43;
        &:hover {
          color: #08a3db !important;
        }
      }
    }
    .footer-actions {
      .btn-action {
        cursor: pointer;
        width: 210px;
        margin-bottom: 3.2rem;
        margin-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: 4.5px solid rgba(255, 211, 0, 1);
        border: 0.5px solid white;
        padding-left: 8px;
        text-align: left;
        position: relative;
        color: #393e43;
        font-weight: bold;
        text-transform: capitalize;
        text-decoration: none;
        transition: color 0.5s ease;

        &:nth-child(1) {
          border-bottom: none;
        }
        &:nth-child(2) {
          border-top: none;
        }
        &:nth-child(3) {
          border-bottom: none;
        }
        span:nth-child(1) {
          margin-top: 8px;
          margin-bottom: 8px;
        }
        .DoubleArrowIcon-wrapper {
          display: flex;
          align-items: center;
          background-color: rgba(255, 211, 0, 1);
          height: 43px !important;
          padding: 0px 5px;
          svg {
            font-size: 20px;
            color: white;
          }
        }
        &:hover svg {
          color: inherit !important;
        }
      }
      .hvr-sweep-to-left {
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
      }
      .hvr-sweep-to-left:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 211, 0, 1);
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      .hvr-sweep-to-left:hover:before,
      .hvr-sweep-to-left:focus:before,
      .hvr-sweep-to-left:active:before {
        transform: scaleX(1);
      }
    }
    #newsletter-signup {
      input {
        border: 4.5px solid rgba(255, 211, 0, 1);
        border-right: 0;
        background-color: transparent;
        font-size: 18px;
        font-weight: 700;
        border-radius: 0;
        padding: 6px 8px;
        position: relative;
        color: #393e43;
        height: auto;
        min-width: 256px;
        outline: 0;
        border-radius: 0 !important;
      }
      input::placeholder {
        font-size: 18px;
      }
      button {
        outline: 0;
        border: none;
        height: 47px;
        background-color: rgba(255, 211, 0, 1);
      }
    }
    .cerifications-image {
      width: 100%;
    }
    .footer-social {
      a {
        text-decoration: none;
        color: inherit;
      }
      .social-links {
        display: flex;
      }
      .footer-social-links-logo {
        color: #65696b;
        font-size: 1.5rem;
      }
      .footer-social-media-logo {
        margin-top: 2%;
        color: #65696b;
      }
      .footer-social-media-logo:hover {
        color: #337ab7;
      }
      .hoverBlue:hover {
        color: #337ab7;
      }
      p:last-child {
        font-size: 14px !important;
      }
      .list-bottom {
        display: flex;
        margin-bottom: 5px;

        a {
          padding-right: 10px;
          border-right: 1px solid #3a3e43;
          color: #3a3e43;
          font-size: 14px !important;
        }

        .border-none {
          border: none;
        }
      }
    }
    .transition-effect {
      display: flex !important;
      align-items: center;
      margin-top: 10px;
      text-decoration: none;
      cursor: pointer;
      &:hover .sweepable-text {
        transform: translate(20px);
        opacity: 1;
      }
    }
    .moncur-logo-wrapper {
      position: absolute;
      bottom: 10px;
    }
    .moncur-logo {
      height: 20px;
      opacity: 0.7;
    }
    .sweepable-text {
      font-size: 15px;
      color: #717172;
      opacity: 0;
      transition: opacity 1000ms;
      transition: transform 700ms;
    }
  }
  .Footer1_Newsletter_Signup_MessageSender_pnlMessageSuccess {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #6fb679;
    width: 90%;
    .icon-wrapper {
      border: 1px solid #6fb679;
      border-radius: 50%;
      height: 5rem;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8%;
      margin-top: 8%;
      .icon {
        height: 25px !important;
        width: 25px !important;
      }
    }
    p {
      font-size: 20px !important;
      margin-bottom: 10%;
    }
  }
}
/* ---Start FooterForCertifiedInstaller css--- */

@media (min-width: 992px) and (max-width: 1199px) {
  .amerikooler-theme {
    .login-box-wrap {
      max-width: 80%;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .amerikooler-theme {
    .login-box-wrap {
      max-width: 80%;
    }
    .login-page {
      max-height: 865px;
    }
    .logo-wrap {
      margin-bottom: 30px !important;
    }
    .login-heading-wrap {
      padding: 0px 5% 15% 5%;
      h1 {
        font-size: 45px;
      }
    }
    /* ---Start FooterForCertifiedInstaller css--- */
    .containerXL {
      #newsletter-signup {
        input {
          min-width: 200px !important;
        }
      }
    }
    /* ---End FooterForCertifiedInstaller css--- */
  }
}
@media (max-width: 768px) {
  body.amerikooler-theme {
    .mbl-hide {
      display: none;
    }
    .des-hide {
      display: block;
    }
    .login-page {
      padding-top: 80px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .login-box-wrap {
      padding: 34px 24px;
      width: 100%;
      margin-top: 0px !important;
      .logo-wrap {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        padding: 20px 0;
        background: #fff;
      }
      .signup-link {
        text-align: left;
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }
    .login-page .left-section {
      order: 1;
      .back-button {
        margin-bottom: -1rem;
        margin-top: 1rem;
      }
    }
    .login-heading-wrap {
      padding: 119px 0 0 0;
      background-size: 100%;
      h1 {
        font-size: 32px;
        line-height: 95%;
        max-width: 193px;
        margin: 24px;
        margin-top: -11%;
      }
      p {
        padding: 24px;
        background: #393e43;
        margin-bottom: 0px;
        max-width: 100%;
      }
    }
    .forget-password-page {
      .login-heading-wrap {
        padding-bottom: 0%;
      }
    }
    /* ---Start-header css--- */
    .container-full {
      width: 90%;
    }
    .loaderForConfigrationPage {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .header-wrap {
      background: #fff;
      padding: 20px 0px;
      border-bottom: 1px solid #ecf1f2;

      .profile-wrap {
        button {
          box-shadow: unset;
          min-width: unset;
          background-image: unset;
          padding: 8px 0px 0px 0px;
        }
      }

      .logo-wrap {
        margin-top: 0px;
        img {
          max-width: 148px;
        }
      }
    }
    /* ---End-header css--- */
    .mbl-second {
      order: 2;
    }
    .signup-page {
      background-position: bottom right;
      padding-bottom: 220px;
      padding-top: 25px;
      &:after {
        content: unset;
      }
      .registration-box {
        background: #fff;
        padding-top: 10px;
        width: 100%;
      }
    }
    /* ---Start FooterForCertifiedInstaller css--- */
    .containerXL {
      .footer-actions {
        .btn-action {
          margin-bottom: 2rem !important;
        }
      }
      .cerifications-image {
        width: 90vw !important ;
      }
      .footer-social {
        .copyright {
          margin-bottom: 2rem !important;
        }
      }
      .Footer1_Newsletter_Signup_MessageSender_pnlMessageSuccess {
        width: 90vw !important;
      }
    }
    /* ---End FooterForCertifiedInstaller css--- */
  }
}
@media (max-width: 368px) {
  body.amerikooler-theme {
    .login-heading-wrap {
      h1 {
        font-size: 27px;
        margin: 20px;
        margin-top: -20%;
      }
      p {
        padding: 18px;
        font-size: 14px;
      }
    }
    /* ---Start FooterForCertifiedInstaller css--- */
    .containerXL {
      #newsletter-signup {
        input {
          min-width: 180px !important;
        }
      }
      .footer-social {
        .copyright {
          width: 20% !important;
        }
      }
    }
    /* ---End FooterForCertifiedInstaller css--- */
  }
}

.maintenance-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    & > h1, & > p{
        text-align: center;
    }
}
/* --- CSS for Registration page--- */
.amerikooler-theme {
  .sideMainImg-heading {
    order: 1;
    font-size: 2.2rem;
    line-height: 95%;
    color: #ffd300;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  .h-100 {
    height: 100%;
    width: 100%;
    float: left;
    // margin-top:2%;
  }

  .secondary-text {
    color: #ffffff;
    font-size: 0.8rem;
    // width: 100%;
  }
}

// set the side image to full height
.sideMainImg-height {
  height: 100vh;
  position: absolute;
  left: 42%;
  right: 0;
  top: 0;
  width: 58%;
  margin: 0;
  padding: 0;
  background: url(/img/login-poster-image.png);
  background-size: cover;
  overflow: auto;
}

//to cover the space around the container
.full-body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  background: #ffffff;
}

// for giving margin to main logo
.logoMain {
  margin-top: 6.25rem !important;
}

.registration-box {
  padding: 5%;
  .create-lead-status-message {
    .Check-Circle-Icon,
    span {
      color: #62a94e;
    }
  }
}
.form-error-wrap {
  display: flex;
  justify-content: center;
}
.default-error {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e05757;
  border-radius: 5px;
  display: flex;
  padding: 18px 13px;
  margin: 20px 12px;
  align-items: center;
  color: #393e43;
  img {
    margin-right: 7px;
  }
}
//resize each col on breakpoint
@media (max-width: 992px) {
  .sideMainImg-height {
    width: 100vw;
    height: 38vh;
    position: static;
    margin: 0;
    padding: 0;
    left: 0%;
    right: 0%;
  }

  .logoMain {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sideMainImg-heading {
    order: 1;
    font-size: 2.2rem;
    line-height: 95%;
    color: #ffd300;
    margin-bottom: 22px;
    text-transform: uppercase;
  }
}

/* --- CSS for Contact Us page--- */

.main-contacts-wrapper {
  .contact-us-box {
    max-width: 700px;
  }
  .form-error-wrap {
    display: flex;
    justify-content: center;
  }
  .default-error {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e05757;
    border-radius: 5px;
    display: flex;
    padding: 18px 13px;
    margin: 20px 12px;
    align-items: center;
    color: #393e43;
    img {
      margin-right: 7px;
    }
  }
  .default-btn {
    margin-top: 20px;
  }
}

//resize each col on breakpoint
@media (max-width: 992px) {
  .sub-wrapper {
    background-color: white;
  }
  .logoMain {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}

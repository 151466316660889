/*This is the main container box*/

.cartContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-family:Roboto Condensed;

  /* This is the padded box for center*/
  .container2 {
    padding: 5px 79px 0px 200px;
    width: 50%;
    top: 191px;
    border-radius: 0px;
  }

  /* This is the main course box containing the title and image*/
  .course-box-heading{
    padding-left:10px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-self: center;
    flex-wrap: wrap;

    /* first heading for title of course*/
    .heading1{
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: #393E43;
    }

    /* second heading for organization name */
    .heading2{
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #393E43;
    }
  }

  /*main box for courses */
  .course-order-box-main{
    margin-top:36px;
  }

  /* sub box for courses */
  .course-order-box{
    background: #F8F9FA;
    display:flex;
    padding:23px;
  }

  /* order box */
  .order {
    border: 1px solid #f5f5f5;
    padding: 30px;
    height: auto;
    background-color: #ffffff;
  }

  /* main heading of success payment box */
  .heading {
    position: static;
    text-transform: uppercase;
  }
  
  /* box for sub heading of success payment box */
  .heading2 {
    position: static;
    float:left;
    font-size:18px;
    line-height: 28px;
    color: #393E43;
    font-style: normal;
    font-weight: normal;
    
  }

  /* for order no response */
  .heading3 {
    position: static;
    font-size:18px;
    line-height: 28px;
    color: #393E43;
    font-style: normal;
    font-weight: normal;
    float:right;
    letter-spacing: 0.02em;
  }

  
  .success-circle{
    width:77px;
    height:77px;
    background: rgba(33, 219, 140, 0.1);
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-content:center;
  }

  /* to display the order no */
  .order-no{
    color:#08A3D3;
  }

  /* main button for starting the course */
  .course-start-btn{
    border:none;
    width: 214px;
    height: 48px;
    background: #08A3D3;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top:24px;
    margin-bottom:24px;
  }

  /* text to display at end of order box*/
  .order-footer-heading{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #B5B8BB;
    margin-bottom:35px;
  }

  @media(max-width:769px){
    .container2{
      width:100%;
    }

    .heading{
      display:flex;
      justify-content: center;
    }

    .heading2{
      display:flex;
      justify-content: center;
      float:none;
    }

    .heading3{
      display:flex;
      float:none;
      justify-content:center;
      font-weight: 300;
    }

    .course-order-box{
      display:flex;
      padding:23px;
      justify-content: center;
      flex-direction: column;

      img{
        margin:auto;
      }
    }

    .course-box-heading{
      display:flex;
      justify-content:center;
      align-self: center;
      flex-direction:column;
      
      .heading1{
        font-size: 20px;
        line-height: 24px;

        text-align: center;
        text-transform: uppercase;
        margin-top:24px;
      }

      .heading2{
        font-weight: 300;
        font-size: 17px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-top:9px;
      }
    }

    .course-start-btn{
      width: 100%;
      height: 48px;
    }

    .course-order-box-main{
      margin-top:24px;
    }

    .order-footer-heading{
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
      text-align:center;
      width:100%;
      margin-bottom:56px;
    }
  }
}

.pre-signupGreeting-wrapper {
  a {
    color: #0084c2;
  }
  .card {
    padding: 30px;
  }
  .freeBird {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .card-content {
    text-align: center;
    .parent-congrats-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
    }
    .congrats-icon {
      height: 70px;
      width: 70px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eef9fd;
    }
    .heading {
      .cong-p {
        font-family: 'Roboto Condensed';
        color: #08a3d3;
        font-weight: 700;
        font-size: 32px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        line-height: 87%;
      }
      span {
        font-size: 22px;
        margin-top: 0px;
      }
    }
    .text-first-block {
      margin-top: 20px;
      margin-bottom: 10px;
      display: block;
    }
    .text-block {
      margin-bottom: 10px;
      display: block;
    }
    .text-last-block {
      margin-bottom: 47px;
      display: block;
    }
    .blue-color {
      color: #0084c2;
      cursor: default;
    }
    .body-content {
      span {
        font-size: 13px;
      }
    }
    .submit-btn {
      display: flex;
      justify-content: center;
    }
    .default-btn {
      padding: 12px 20px 9px 20px;
      box-shadow: unset;
      background: #08a3d3;
      border: 1px solid #08a3d3;
      color: #fff;
      border-radius: 0px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 700;
      font-family: 'Roboto Condensed', sans-serif;
      &:disabled {
        background: #ecf1f2;
        color: #b5b8bb;
        border: 1px solid #ecf1f2;
        cursor: not-allowed;
      }
    }
  }
}
.form-error-wrap {
  .default-error {
    background: #ffffff;
    border: 1px solid #e05757;
    border-radius: 5px;
    display: flex;
    padding: 6px 13px;
    margin: 20px 28px;
    align-items: center;
    color: #393e43;
    .warningIcon {
      color: #fd7f0a;
      font-size: 20px;
      margin-right: 7px;
    }
  }
}
.alert-box-wrap {
  width: 100%;
  max-width: 730px;
  flex: 0 0 60%;
  @media (max-width: 767px) {
    flex: 0 0 95%;
  }
  .card {
    padding-bottom: 50px;

    hr {
      border: 1px solid #ecf1f2;
    }
  }
  .warning-messge {
    .default-error {
      border: 1px solid #ee9644;
      color: #ee9644;
      background: #fef6ef;
      @media (max-width: 767px) {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .alert-box-content {
    p {
      font-size: 18px;
      color: #636a72;
      line-height: 28px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 400px) {
        font-size: 15px;
      }
      a {
        color: #08a3d3;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .card {
    padding: 20px !important;
  }
}
@media (max-width: 767px) {
  .pre-signupGreeting-wrapper .card-content .parent-congrats-icon {
    margin-bottom: 20px;
  }
  .pre-signupGreeting-wrapper .card-content .heading .cong-p {
    font-size: 28px;
  }
  .pre-signupGreeting-wrapper .card-content .congrats-icon {
    width: 60px;
    height: 60px;
  }

  .pre-signupGreeting-wrapper {
    .freeBird {
      position: unset;
      overflow-y: auto;
      padding: 0px 0px 100px 0px;
      background: url(/img/body-bottom-img.svg) no-repeat right 0 bottom;
      background-size: 150px;
      > .container {
        height: unset !important;
      }
    }
    .hideOnMobile {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .card {
    padding: 10px !important;
  }
  .heading {
    .congj-p {
      font-size: 18px !important;
    }
    span {
      font-size: 20px !important;
    }
  }
  .body-content {
    .text-first-block {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .text-block {
      margin-bottom: 10px !important;
    }
    .text-last-block {
      margin-bottom: 15px !important;
    }
  }
  .resent-btn-wrapper {
    text-align: center !important;
  }
}

.MenuBar {
  button {
    float: right;
    margin: 25px 0px 0px 0px;
    text-align: center;
  }
}

// --- for hiding Header Menu --- //
.hideOnTop{
  display:none;
}

@media only screen and (max-width: 767px) {
  .hideOnTop{
    display:block;
  }
}

//---- end of hide ----- //

.MenuIcons {
  color: #234a96;
  margin-right: 20px;
}
.MenuBarIcon {
  color: #fff;
}

.Link {
  color: #3f51b5 !important;
}
.Link:hover {
  text-decoration: none !important;
}
@media (max-width: 1100px) {
  .MenuBar {
    button {
      margin: 20px 0px;
      text-align: right;
      padding: 6px 0px;
      .MuiButton-label {
        display: block;
      }
    }
  }
}

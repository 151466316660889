
.cert-no-rec-heading{
    border:1px solid #b28dcb ;
    background:#ffffff;
    border-radius:5px;
    padding:2% 15%;
}

.certified-installer-wrap{
    padding:20px 0px 50px 0px;
    .head-title{
        margin-bottom:0px;
        margin-top: 10px;
    }
    .pege-detail{
        font-size: 14px;
        line-height: 16px;
        color: #000;
    }
    .certified-installer-head{
        margin-bottom: 20px;
    }

    .certifiedInstaller-box{
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-shadow: 0px 0px 15px #EFEFEF;
        margin-bottom: 30px;
        padding: 40px 16px;
        .installer-name-wrap{
            border-bottom: 1px solid  #ECF1F2;
            padding-bottom: 16px;
            margin-bottom: 16px;
            h3{
                margin-bottom: 0px;
                font-size: 18px;
                line-height: 21px;
            }
            p{
                font-size: 12px;
                line-height: 16px;
            }
        }
        .address-section{
            margin-bottom: 10px;
            color: #393E43;
            font-size: 14px;
            img{
                margin-right: 12px;
                width: 18px;
                vertical-align: top;
            }
        }
        .phone-section{
            margin-bottom: 10px;
            color: #393E43;
            font-size: 14px;
            img{
                margin-right: 12px;
                width: 18px;
                vertical-align: top;
            }
        }
    }
    .shadow-none{
        box-shadow: unset;
    }
    .pegination-wrap{
        .MuiPagination-root{
            display: flex;
            ul{
                margin-left: auto;

                .MuiPaginationItem-icon{
                    color: #08A3D3;
                }
                .MuiPaginationItem-page{
                    color: #686f77;
                    &.Mui-selected{
                        background:  #08A3D3;
                        color: #fff;
                        border-radius: 0px;
                    }
                }
            }
        }
    }
    
}

@media (max-width: 767px) {
   
    .certified-installer-wrap {
        .certifiedInstaller-box{
            padding:32px 16px;
            .address-section,.phone-section{
                font-size: 12px;
            }
        } 
    }
   
}
/* ResetPasswordError page*/

.pre-resetpassworderror-wrapper {
  a {
    color: #0084c2;
  }
  .card {
    padding: 30px !important;
    margin-top: -110px;
  }

  .card-content {
    text-align: center;
    .parent-frame-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
    }

    .heading {
      span {
        font-size: 22px;
        margin-top: 0px;
      }
    }

    .blue-color {
      color: #0084c2;
      cursor: default;
    }
    .body-content {
      span {
        font-size: 14px;
      }
    }
    .warningIcon {
      color: #636a72;
      font-size: 40px;
      margin-right: 7px;
    }
    .default-btn {
      padding: 12px 20px 9px;
      box-shadow: unset;
      background: #08a3d3;
      color: #fff;
      border-radius: 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      &:disabled {
        background: #ecf1f2;
        color: #b5b8bb;
        border: 1px solid #ecf1f2;
        cursor: not-allowed;
      }
    }
    .signin-link {
      margin-top: 20px;
      font-size: 15px;
      color: #636a72;
    }
  }
}
.card {
  padding-bottom: 50px;

  hr {
    border: 1px solid #ecf1f2;
  }
  .alert-box-content {
    p {
      font-size: 18px;
      color: #636a72;
      line-height: 28px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 400px) {
        font-size: 15px;
      }
      a {
        color: #08a3d3;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .card {
    padding: 30px !important;
  }
  .hideOnMobile {
    display: block;
  }
}
@media (max-width: 767px) {
  .pre-thankyou-wrapper .card-content .parent-frame-icon {
    margin-bottom: 20px;
  }
  .pre-thankyou-wrapper .card-content .heading .frame-p {
    font-size: 28px;
  }
  .pre-thankyou-wrapper .card-content .frame-icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .hideOnMobile {
    display: block;
    transform: scaleX(-1);
    right: 0px !important;
    bottom: 0px !important;
  }

  .card {
    padding: 15px !important;
  }

  .heading {
    span {
      font-size: 18px !important;
    }
  }
}

body {
  background: #fff;
}
.app-main-content {
  background: #f9fbfd;
}
.MuiButton-root {
  text-transform: none !important;
}
button {
  /*padding: 0px !important;*/
  justify-content: center !important;
}

.forgetcontent {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  .forgetpwd {
    height: 100%;
  }
  header {
    background: #525ca3;
  }
  .app-container {
    height: 100%;
  }
  .forgotpaawddiv {
    padding-top: 50px;
    p {
      padding-bottom: 30px;
      text-align: center;
    }
  }
  .processFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

.CheckImg {
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
}
/* .ForgotPassword {
  width: 100%;
  .forgotpaawddiv {
    margin-top: 50px;
    .forgotPwdLbl {
      text-align: center;
      margin-bottom: 20px;
    }
    .textField {
      margin-bottom: 20px;
    }
  }
  .processFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
} */
.SignIn-avatar-2 {
  background: #3f51b5 !important;
}
.forgetPwdLink {
  text-align: center;
  font-family: ITC Avant Garde Std Md;
}
// .jss2 {
//   margin: 8px;
//   background-color: #01418c;
// }
.passwordEyeIcon {
  color: #01418c;
  cursor: pointer;
}
.OptionBoxDiv {
  margin-top: 40px;
  .AnsBoxOptionDiv {
    width: 80px;
    height: 40px;
    float: left;
    margin: 10px;
    border-radius: 2px;
    background-color: #d4d4d4;
    .optLbl {
      text-align: center;
      color: #000;
      padding: 9px 20px;
    }
  }
}

.ViewFeedback {
  font-family: Roboto;
  font-size: 16px;
  text-align: left;
  color: #234a96;
  margin: 10px 0px !important;
  cursor: pointer;
}
.mtb-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.doneTickIcon {
  display: none;
  padding-right: 8px;
}
.wrongIcon {
  display: none;
  padding-right: 8px;
}
.attempCss {
  padding: 0px !important;
  background: #dfecfb;
}
.MuiListItem-root {
  padding: 0px;
}

.btnReviewLesson {
  border-radius: 50px;
  padding: 12px 25px;
  background: none;
  text-transform: capitalized;
  width: 100%;
  max-width: 150px;
  height: 50px;
  color: #01418c;
  border: solid 2px #01418c;
  font-size: 16px;
  font-weight: bold;
  margin: 0px 10px;
  &:hover {
    border: solid 2px #01418c;
  }
}

.BasicOperationDetails {
  .statusProcessDiv {
    margin-top: 20px;
    .progress {
      background: #fff;
      height: 35px;
      .progress-bar {
        margin-left: 5px;
        margin-right: 5px;
        background: #d8d8d8;
        border-radius: 5px;
        height: 8px;
        .InnerSpan {
          background: #427821;
          width: 100%;
          // max-width: 60px;
          border-radius: 5px;
          color: #427821;
        }
        .OperationLblSpan {
          position: absolute;
          bottom: 0;
          font-size: 12px;
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 137px;
          color: #234a96;
        }
        .OperationLblSpangrey {
          position: absolute;
          bottom: 0;
          font-size: 12px;
          font-weight: normal;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 137px;
          overflow: hidden;
          color: #9b9b9b;
        }
      }
    }
  }
  .card {
    width: 100%;
    max-width: 768px;

    object-fit: contain;
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .CourseStatusLbl {
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      float: left;

      letter-spacing: normal;
      color: #4a4a4a;
    }
    .ShowDetailsLbl {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      float: right;
      cursor: pointer;
      letter-spacing: normal;
      color: #234a96;
      user-select: none;
      &:hover {
        color: #234a96;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0);
      }
    }
    .ShowDetailsLabelDisabled {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      float: right;
      cursor: default;
      letter-spacing: normal;
      color: #4a4a4a;
      user-select: none;
      &:hover {
        color: #4a4a4a;
        text-decoration: none;
      }
    }
    .Content {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;

      color: #4a4a4a;
      .TruckImage {
        width: 26px;
        height: 15px;
      }
      .ListItemStyle {
        font-weight: bold;
        color: #4a4a4a;
        font-size: 16px;
      }
    }
  }
  .ListTypeUl {
    li {
      list-style-type: none;
      font-size: 16px;
      color: #4a4a4a;
      font-family: Roboto;
      padding: 5px 0px;
    }
  }
  .dotborder {
    border: 1px dashed #01418c;
    padding: 3px;
    float: left;
  }
  .NoList {
    font-weight: bold;
    color: #01418c;
    float: left;
    padding: 12px 0px;
    padding-right: 8px;
  }

  .AnsBoxOption {
    width: 100%;
    max-width: 76px;
    height: 40px;
    float: left;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 2px;
    border: dashed 1px #9b9b9b;
  }
  // .AnsBox {
  //   width: 100%;
  //   max-width: 190px;
  //   height: 40px;
  //   object-fit: contain;
  //   border-radius: 2px;
  //   background-color: #e3f0ff;
  // }
}
.centerdiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.linkfeedback {
  cursor: pointer;
}
.bg {
  background: #f4f7fc;
  margin-bottom: 27px;
  color: #525ca3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  .OnlineCourseLbl {
    color: #525ca3;
  }
}
.title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold !important;

  letter-spacing: normal;
  color: #01418c !important;
}
.headingBg {
  width: 100%;
  margin: 0 auto;
  height: auto;
  padding: 6px 0px;
}
.OnlineCourseLbl {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.btnViewCertificate {
  border-radius: 50px !important;
  padding: 12px 25px;
  background-image: linear-gradient(to right, #1f4594 0%, #3f51b5 100%);
  text-transform: capitalized;
  width: 100%;
  margin: 20px 10px;
  height: 50px;
  max-width: 280px;

  font-size: 16px;
  font-weight: bold;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.btnWrapForgotPassword {
  display: flex;
}
.btnWrap {
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 0 auto;

  .Mui-disabled {
    background: #ccc !important;
    border: solid 2px #ccc !important;
    color: #fff !important;
    box-shadow: none !important;
  }
  .btnCancel {
    border-radius: 50px;
    padding: 12px 25px;
    background: transparent;
    text-transform: capitalized;
    width: 100%;
    max-width: 150px;
    height: 50px;
    border: solid 2px #01418c;
    font-size: 16px;
    color: #01418c;
    box-shadow: none;
    font-weight: bold;
    margin-right: 20px;
    &:hover {
      border-radius: 50px;
      padding: 12px 25px;
      background: transparent;
      text-transform: capitalized;
      width: 100%;
      max-width: 150px;
      height: 50px;
      border: solid 2px #01418c;
      font-size: 16px;
      color: #01418c;
      box-shadow: none;
      font-weight: bold;
      margin-right: 20px;
    }
    &:disabled {
      background-color: #ccc !important;
      color: #fff !important;
    }
  }
  .btnNext {
    border-radius: 50px;
    padding: 12px 25px;
    background-image: linear-gradient(to right, #1f4594 0%, #3f51b5 100%);

    text-transform: capitalized;
    width: 100%;

    height: 50px;
    max-width: 150px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
}
.AnsRectangleblue {
  width: 100%;
  /*   max-width: 280px; */
  height: auto;
  /*  height: 40px; */

  border-radius: 2px;
  background-color: #e3f0ff;
  .ansLbl {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px;
    cursor: pointer;
    color: #01418c;
  }
  .greenAns {
    background-image: linear-gradient(to right, #3f671f 0%, #448622 100%);
    color: #fff;
  }
  .RedAns {
    color: #790000;
    // border: solid 2px #870000;
    background-color: #ffe0e0;
  }
  .ansLbl[disabled] {
    pointer-events: none;
  }
}
/* Vertical Timeline Steper */
ul.timeline {
  list-style-type: none;
  position: relative;
  .CheckImgTimeline {
    width: 32px;
    position: relative;
    left: -44px;
    top: 0px;
    background: #fff;
    z-index: 2;
  }
  .TimelineHeading {
    position: relative;
    display: inline;
    /* right: 30px; */
    margin-left: -29px;
    font-weight: bold !important;
  }
  .GreyCheck {
    left: 13px;
    position: absolute;
  }
  .CheckImgBlank {
    display: inline-block;
    height: 14.4px;
    width: 24px;
  }
}
ul.timeline:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 0;
  border-left: 2px dashed var(--green);
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  //   border: 3px solid #437c21;
  left: 16px;
  width: 30px;
  height: 30px;
  z-index: 0;
}
.verticalTimelineContent {
  .TimelineSubList {
    padding-inline-start: 0px;

    li {
      list-style-type: none;

      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      margin: 10px 0px;
      color: #01418c;
      img {
        margin-right: 10px;
      }
      .checkIconList {
        color: #22862e;

        font-weight: bold;
      }
      span:nth-child(2) {
        max-width: 87%;
        display: inline-table;
      }
    }
  }
  .GreyText {
    left: 0;
    color: #4a4a4a;
    font-weight: bold;
    cursor: default;
    margin-left: 0px !important;
  }
  .blueText {
    cursor: pointer;
    color: #01418c;
  }
}
/* End */

// Disable text selection highlighting
.notextselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.popover {
  .close {
    font-size: 20px !important;
    position: absolute;
    right: 7px;
    top: 6px;
  }

  .popover-body,
  .popover-inner {
    padding: 0px 38px 0px 20px;
  }
}
@media (min-width: 320px) and (max-width: 600px) {
  .bg {
    margin-bottom: 15px;
  }
  .btnViewCertificate {
    width: 100%;
    max-width: 100%;
  }
  .popover {
    /*top: -26px !important;*/
    top: -7px !important;
    left: -3px !important;
    max-width: 228px !important;

    .bs-popover-bottom,
    .bs-popover-auto[x-placement^='bottom'] {
      margin-top: 28px;
    }
    .close {
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
  .forgetPwdLink {
    justify-content: center;
    align-items: center;
    display: flex;
    .forgetPwd {
      float: none;
    }
  }
  .centerdiv {
    width: 100%;
  }
  .TermsConLbl {
    font-size: 14px;
    vertical-align: text-bottom;
  }
  .PrePaidCodeContentTwo {
    margin-top: 0px;
  }
  .OnlineCourseLbl {
    font-size: 14px;
  }
  /*   .ViewFeedback {
    text-align: center !important;
  } */
  .headingBg {
    width: 100%;
    margin: 0 auto;
    height: auto;
    padding: 9px 0px;
  }
  .dispFlex {
    display: flex;
  }
  .btnWrap {
    justify-content: center;
    align-items: center;

    width: 100%;
    /*  display: flex; */
    margin: 0 auto;
    .btnNext {
      max-width: 130px;
      width: 100%;
      float: right;
    }
    .btndisplay {
      display: flex;
    }
    .btnCancel {
      width: 130px;
      float: left;
      &:hover {
        width: 130px;
      }
      &:disabled {
        background-color: #ccc !important;
        color: #fff !important;
      }
    }
  }
}
@media (max-width: 991px) {
  .AnsRectangleblue {
    /*   max-width: 145px; */
    width: 100%;
  }
  .OperationLblSpangrey {
    display: none;
  }
  .OperationLblSpan {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1326px) {
  .bg {
    margin-bottom: 15px;
  }
  .OnlineCourseLbl {
    font-size: 14px;
  }
  .OperationLblSpan {
    font-size: 10px !important;
  }
  .OperationLblSpangrey {
    font-size: 10px !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  ul.timeline:before {
    content: ' ';
    background: #fff;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 0;
    border-left: 2px dashed green;
  }
}
@media only screen and (min-width: 992px) {
  .container-fluid {
    width: 94%;
  }
}

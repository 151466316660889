.pre-emailVerification-wrapper {
  a {
    color: #0084c2;
  }
  .card {
    padding: 30px;
  }
  .freeBird {
    position: absolute;
    width: 100%;
    height: 100%;
    .card-z-index{
      z-index: 1;
    }
  }
  .card-content {
    text-align: center;
    .parent-frame-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
    }
    .frame-icon {
      height: 70px;
      width: 70px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eef9fd;
    }
    .frame-error-icon {
      height: 70px;
      width: 70px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FEF7F5;
    }
    .heading {
      .cong-p {
        font-family: 'Roboto Condensed';
        color: #0084c2;
        font-weight: 700;
        font-size: 32px;
      }
      .cong-p-error {
        color: #E05757;
      }
      span {
        font-size: 22px;
        margin-top: 0px;
      }
    }
    .text-first-block {
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
    }
    .text-block {
      margin-bottom: 20px;
      display: block;
    }
    .text-last-block {
      margin-bottom: 30px;
      display: block;
    }
    .blue-color {
      color: #0084c2;
      cursor: default;
    }
    .body-content {
      span {
        font-size: 14px;
      }
    }
    .default-btn {
      padding: 12px 20px 9px 20px;
      width: 50%;
      box-shadow: unset;
      background: #08a3d3;
      border: 1px solid #08a3d3;
      color: #fff;
      border-radius: 0px;
      font-size: 17px;
      line-height: 24px;
      font-weight: 700;
      font-family: 'Roboto Condensed', sans-serif;
      // &:hover {
      //   background: #fff;
      //   color: #08a3d3;
      //   border: 1px solid #08a3d3;
      // }
      &:disabled {
        background: #ecf1f2;
        color: #b5b8bb;
        border: 1px solid #ecf1f2;
        cursor: not-allowed;
      }
    }
  }
}
.card {
  padding-bottom: 50px;

  hr {
    border: 1px solid #ecf1f2;
  }
  .alert-box-content {
    p {
      font-size: 18px;
      color: #636a72;
      line-height: 28px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 400px) {
        font-size: 15px;
      }
      a {
        color: #08a3d3;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .card {
    padding: 30px !important;
  }
  .hideOnMobile {
    display: block;
  }
}
@media (max-width: 767px) {
  .pre-signupGreeting-wrapper .card-content .parent-congrats-icon {
    margin-bottom: 20px;
  }
  .pre-emailVerification-wrapper .card-content .heading .cong-p {
    font-size: 28px;
  }
  .pre-signupGreeting-wrapper .card-content .congrats-icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .hideOnMobile {
    display: block;
    transform: scaleX(-1);
    right: 0px !important;
    bottom: 0px !important;
  }

  .card {
    padding: 15px !important;
  }
  .heading {
    .congj-p {
      font-size: 18px !important;
    }
    span {
      font-size: 18px !important;
    }
  }
}

/*Error Styles*/

.page-error-container {
  padding: 30px 0;
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
}

.page-error-content {
  margin: 0 auto;
  padding: 8% 0 40px 0px;
  width: 380px;
  max-width: 94%;
    h4{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 18px;
      color:  #2B2B38;
      @media screen and (max-width: 767px) {
        padding:10px 15px;
      }
    }
    .btn-primary{
      &.btn{
      font-size: 16px !important;
      line-height: 19px;
      height: 40px;
      max-width: 210px;
      width:100%;
      padding: 10px 15px;
      background: #525CA3;
      border-radius: 5px !important;
      border:0px;
      letter-spacing: 0.02em;
      font-weight: normal !important;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 128px;
  }
}

.error-code {
  color: #525ca3;
  opacity: 0.3;
  font-size: 56px;
  text-align: center;
  line-height: 56px;
  font-weight: bold;
  font-family: ITC Avant Garde Std Md;

}
.page-error-content h2 {
  margin-bottom: $size-20;
  font-size: 28px;
  line-height: 36px;
  color: #525CA3 !important;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

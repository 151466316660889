/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}
.loginWrapper {
  .rememberpass {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #525ca3;
    margin-bottom: 18px;
  }
  .loginTitle {
    font-family: 'ITCAvantGardeStd-Demi';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 18px;
    align-items: center;
    text-align: center;
    color: #525ca3;
  }

  .MuiFilledInput-input {
    background: #f4f7fc;
    border-radius: 5px;
  }
  .MuiFilledInput-underline:before {
    border-bottom: 0px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    .MuiInputLabel-filled {
      color: #525ca3;
    }
  }
  .MuiFilledInput-root {
    background: #f4f7fc !important;
    border-radius: 5px;
    font-family: 'Lato-Regular';
    .textDecorationUnderlineDefault {
      font-size: 12px;
      color: #525ca3;
    }
    &:hover {
      background: #f4f7fc;
    }
  }
  .formContent {
    width: 100%;
    margin: 8px;
    .bgBlue {
      // background-image: linear-gradient(to right, #525ca3 0%, #525ca3 100%);
      background-color: #525ca3;
      &:disabled {
        color: #fff !important;
        box-shadow: none !important;
        background: #ccc !important;
        border: none !important;
      }
    }
    .forgetPwdLink {
      a {
        text-decoration: underline;
      }
    }
  }

  .disabled-forgot {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}

.login-header {
  margin-bottom: 30px;
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(10px);
  font-size: 14px;
  overflow: hidden;
}

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
  }
}

.app-login-header {
  margin-bottom: 30px;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.ForgetPassword-warper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 430px;
  width: 100%;
  margin: 120px auto 15px auto;
  .forgetpasswordTitle {
    font-family: 'ITCAvantGardeStd-Demi';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 35px;
    line-height: 48px;
    margin-bottom: 18px;
    align-items: center;
    text-align: center;
    color: #525ca3;
  }
}
.forgetcontent {
  .forgotpaawddiv {
    .MuiFormControl-root {
      background: #f4f7fc;
      border-radius: 5px;
      font-family: 'Lato-Regular';
      margin-top: 0px;
      .MuiInputBase-input {
        padding: 27px 12px 10px 12px;
      }
      .MuiInputLabel-formControl {
        transform: translate(12px, 20px) scale(1);
        &.MuiInputLabel-shrink {
          transform: translate(12px, 10px) scale(0.75);
        }
      }
      .MuiInput-formControl {
        margin-top: 0px;
        &:before {
          border: 0px;
        }
      }
    }
    p.Error {
      padding-bottom: 0px;
    }
  }
}
.btnWrap .fullwidth-button {
  width: 100%;
  padding: 10px 25px !important;
  font-size: 16px;
  background: #525ca3;
  font-family: ITC Avant Garde Std Md;
  border-radius: 50px;
  margin-bottom: 15px;
}
#password::-ms-reveal,
#password::-ms-clear {
  display: none;
}

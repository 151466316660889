.processFooter {
  background-color: #000000;
  color: #d4d4d4;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  .copyrightLbkl {
    font-size: 14px;
  }
}

.headingSection {
  background: #f4f7fc;
  padding: 13px 0px;
  font-family: ITC Avant Garde Std Md;
  a {
    color: #525ca3;
  }
  li {
    float: left;
    &.MuiBreadcrumbs-separator {
      padding-top: 3px;
    }
  }
  p {
    font-size: 16px;
    font-family: 'Lato-Regular';
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #525ca3;
  }
  .breadCrumbs-link {
    cursor: pointer;
  }
}
